import React, {Component} from 'react'
import { connect } from 'react-redux'
import { setLocale, getLocale, localizeKey } from 'react-redux-localization';
import { Cookies } from 'react-cookie' 
import '../../styles/App.css'
import '../../styles/home.css'
import {Col, Row,FormControl, Form, FormGroup, ControlLabel, Button} from 'react-bootstrap'
import { createElementsFromText } from 'html-text-to-react';
import ReactHtmlParser from 'react-html-parser';
import $ from 'jquery';
import {TOKEN_NAME, REDIRECT,HOME_PAGE_LOADED, APP_LOAD,LANGUAGE_KEY} from '../../constants/actionTypes'
import agent from '../../agent'
import HomeList from './homeList.js'
import { Link} from 'react-router-dom'
import Pusher from 'pusher-js';
import Lqhome from '../common/home.json'
import menu from '../common/menu.json';
import '@fortawesome/fontawesome-free/css/fontawesome.css'
import '@fortawesome/fontawesome-free/css/brands.css'
import '@fortawesome/fontawesome-free/css/solid.css'
import '@fortawesome/fontawesome-free/css/all.css'


const mapStateToProps = state => (
    Object.assign({}, state, {
		dashboardData: state.common.dashboardData,
		tab: state.common.tab,
		updateProps: state.common.updateProps,
		locale: getLocale(state),
		languageKey: state.common.languageKey,
		search_key: state.common.search_key,
		only_friend: state.common.only_friend,
	}) 

);

const mapDispatchToProps = dispatch => ({               
	onLoad: (data) => {
		dispatch({ type: 'HOME_PAGE_LOADED', data})  
	},
	chooseLocale: locale => dispatch(setLocale(locale))
});



 
var pusher = new Pusher('d75bec97873cadcd8a43', {
	cluster: 'ap1',
	forceTLS: true
});

class Home extends Component{ 	
	constructor(){
		super()
		this.state = {
			lguages:'1005',
			qcinfo:{},textlink:'',
			homebetList:[],only_friend:'0',isLoading:false,limit:15,offset:0,filelist:[],
		}
		//---
		this.getrandomad=()=>{
			let lang_id=this.state.lguages
			let param={lang_id:lang_id}
			agent.home.getrandomad2(param).then(data => {
				//console.log(data)
				if(data.result === 'good'){
					this.setState({qcinfo:data.info,textlink:data.textlink})
				}
			})
		}
		//---
		this.loadMore=()=>{
			let offset = parseInt(this.state.offset) + this.state.limit
			this.setState({isLoading:true,offset},()=>{
				this.getHomeList();
			})
			
		}
		this.getaddlogos=()=>{
			agent.auth.getaddlogos({}).then(datas => {
				if(datas.result==="good"){
					let filelist=datas.filelist;
					this.setState({filelist:filelist})
					console.log(filelist)
				}
			})
		}
		//---
		this.goAdView=this.goAdView.bind(this)
	}
	goAdView(ev){
		let id=$(ev.target).attr("dataid")
		//let title=$(ev.target).attr("title")
		//window.localStorage.setItem( 'ad_user_id', id);
		//window.localStorage.setItem( 'ad_title', title);
		//console.log(id,title)
		window.location.href='/new-info/'+id 
	}
	componentWillMount(){	
		//-----
		//this.getHomeList();
		
		let idLanguage=window.localStorage.getItem(LANGUAGE_KEY)
		if(idLanguage!='' && idLanguage!='null' && idLanguage!=null){
			this.setState({lguages:idLanguage},()=>{
				this.getrandomad();
			})
		}
		let only_priend=window.localStorage.getItem('only_priend')
		//console.log(only_priend)
		this.setState({only_friend:only_priend},()=>{
			this.getHomeList()
		})
		this.getSocketForNum();
		this.getaddlogos();
		//-----
	}
	componentDidMount(){
		this.getrandomad();
	}
	componentWillReceiveProps(nextProps){
		//console.log(nextProps)
		if(nextProps.languageKey && nextProps.languageKey!='' && nextProps.languageKey!=null && nextProps.languageKey!='null'){
			this.setState({lguages:nextProps.languageKey},()=>{
				this.getrandomad()
			});
			
		}
		if(nextProps.search_key!=null && nextProps.search_key!='null' ){
			this.getHomeListSearch()
		}
		//console.log(nextProps.only_friend,this.state.only_friend)
		if(nextProps.only_friend!=null && nextProps.only_friend!='null' && nextProps.only_friend !=this.state.only_friend ){
			this.setState({only_friend:nextProps.only_friend},()=>{
				this.getHomeList()
			})
		}
	}
	
	shouldComponentUpdate(nextProps, nextState){
		return nextState;
	}	
	getHomeList(){
		$(".imgload").show()
		let search=$(".txt-search").val();
		let only_friends=this.state.only_friend
		let lang_id=this.state.lguages
		let limit=this.state.limit
		let offset=this.state.offset
		agent.home.mobilehomebetlist({search:search,only_friends:only_friends,lang_id:lang_id,limit:limit,offset:offset}).then(data => {
			//console.log(data)
			if(data.result === 'good'){
				let homebetList=this.state.homebetList.concat(data.list)
				this.setState({homebetList:homebetList,isLoading:false})
				//this.setState({homebetList:data.list})
				$(".imgload").hide()
			}else{
				this.setState({homebetList:this.state.homebetList})
				$(".imgload").hide()
			}
		})
	}
	getHomeListSearch(){
		$(".imgload").show()
		let search=$(".txt-search").val();
		let only_friends=this.state.only_friend
		let lang_id=this.state.lguages
		let limit=this.state.limit
		let offset=this.state.offset
		agent.home.mobilehomebetlist({search:search,only_friends:only_friends,lang_id:lang_id,limit:limit,offset:0}).then(data => {
			//console.log(data)
			if(data.result === 'good'){
				let homebetList=data.list
				this.setState({homebetList:homebetList,isLoading:false})
				//this.setState({homebetList:data.list})
				$(".imgload").hide()
			}else{
				this.setState({homebetList:[]})
				$(".imgload").hide()
			}
		})
	}
	getSocketForNum(){
		var thiss=this
		var channelcode = 'coopbet-pick';
		var event = 'pickcoming-changed';
		var channel = pusher.subscribe(channelcode);
		channel.bind(event, function(data) {
			//alert(JSON.stringify(data));
			//console.log(data)
			var homebetList=thiss.state.homebetList
			var PickCodeComingNumber=data.PickCodeComingNumber
			var PickCodeComingUsers=data.PickCodeComingUsers
			let newhomeList=homebetList.map(dt=>{
				if(dt.PickCode==data.PickCode){
					dt.PickCodeComingNumber=PickCodeComingNumber
					dt.PickCodeComingUsers=PickCodeComingUsers
				}
				return dt;
			})
			//console.log(newhomeList)
			thiss.setState({homebetList:newhomeList})
		});
	}
	render(){   
		//---
		const qcinfo=this.state.qcinfo;
		let title=''
		let imageurl=''
		let url=''
		let description=''
		if(qcinfo!='' && qcinfo!=null && qcinfo!='null'){
			 title=qcinfo.ad_title?qcinfo.ad_title:'';
			 imageurl=qcinfo.ad_logo_url?qcinfo.ad_logo_url:'https://back-end.coopbet.com/gf/ywqBBMhT0U0022_no-logo.png';
			 url=qcinfo.ad_url?qcinfo.ad_url:'';
			 description=qcinfo.ad_description?qcinfo.ad_description:'';
			 description=(description.length<=85)?description:description.substr(0,85)+'...'
		}	
		//---
		let linkcado=''
		if(this.state.textlink!==undefined && this.state.textlink!==''){
			let linkT=this.state.textlink.split(" ");
			linkcado=linkT[linkT.length-1]
		}
		let filelist=this.state.filelist;
		//console.log(this.state.homebetList)
        return (
			<div id="body">   
			{
				(qcinfo && qcinfo!=null && qcinfo!='')?
				<Row className="randomad">
					<Col md={4} xs={4} sm={4} className="pd0">
						<div className="img-adv">
							<a href={url} target="_blank" className="rand-logo">
								<img alt="m88" src={imageurl} width="100" height="50"/>
							</a>
						</div>
					</Col>
					<Col md={8} xs={8} sm={8} className="pd0">
						<div className="desc-adv">									
						{
							ReactHtmlParser(description)
							
						}
							<span>
							{
								(qcinfo!='' && qcinfo!=null)?
								<a onClick={this.goAdView.bind(this)} dataid={qcinfo.id} title={title} className="v-more">{localizeKey(Lqhome)('viewmore', this.state.lguages)}</a>:''
							}
							</span>
						</div>
					</Col>
				</Row>:''
			}
				<Row>
					<Col md={12} className="lnk-kito">  
						<a href="http://bit.ly/cadovnforum" target="_blank" className="lnk-kido">
						<b>	V9bet – Đối Tác Thương Hiệu độc quyền của Cá Độ VN và CoopBet </b>
						</a>
					</Col>
				</Row>
				<Row>
					<Col md={12} className="lnk-cado">  
						<a href={linkcado} target="_blank" className="lnk-cado">
							<b> {this.state.textlink} </b>
						</a>
					</Col>
				</Row>
				<Row className="line-ct"></Row>
				<Row>
					<Col md={12} className="linklogo" style={{'textAlign':'right','margin':'5px','display':'flex'}}>
						<i style={{'fontWeight':'600','color':'#999','marginRight':'3px','width':'80px'}}>Powered by: </i>
						
						<div>
						{
							(filelist!=undefined && filelist!=null)?filelist.map((dt,i)=>{
								//if(i<3){
									//let link =(i==0)?'https://ktoviet.com/vn83736c':dt.linkto;
									let link = dt.linkto;
									return(
										<a href={link} target="_blank" key={i}>
											<img src={dt.fileurl} style={{'margin':'0 2px'}}/>
										</a>
									)
								//}
							}):''
						}
						</div>
					</Col>
				</Row>
				<Row className="line-ct"></Row>
				<Row className="addTip">
					<Col md={7} sm={7} xs={7} className="txt-r"> {localizeKey(Lqhome)('doywpost', this.state.lguages)}</Col>
					<Col md={5} sm={5} xs={5}> 
						<Link className="btn-create-tip" to="/add-tip">{localizeKey(menu)('createtip', this.state.lguages)}</Link>
					</Col>
				</Row>
				<Row className="line-ct" style={{'border':'1px solid #dad7d7'}}></Row>
				<HomeList homebetList={this.state.homebetList}/>
				{
					(this.state.homebetList!='' && this.state.homebetList!=null && this.state.homebetList.length>5)?
					<Row className="mg0">
						<Col md={12} className="div-load-more">
							<Button
								id="load-more"
								onClick={!this.state.isLoading ? this.loadMore : null}>
								{this.state.isLoading ? <span style={{'fontSize':'10px'}}><i className='fa fa-spinner fa-spin '></i> LOAD MORE ...</span> :<span>LOAD MORE </span>}
							 </Button>
						</Col>
					</Row>:''
				}
			</div>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Home)